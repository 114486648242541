import React from "react";
import "./Specs.css";
import RedLine from "../RedLine/RedLine";
import Container from "../Container/Container";
import midsection1 from "../../assets/midsection1.jpeg";
import midsection3 from "../../assets/pexels-didsss-3657268.jpg";
import midsection2 from "../../assets/pexels-ashford-marx-1565533-12846562.jpg";

export default function Specs() {
  return (
    <section className="specs">
      <Container>
        <div className="content">
          <div className="col-1">
            <RedLine type="small" />
            <h2 className="title">
              Realtime Tracking
              <br /> World's most compact airspace
              <br /> built for UAVs 
            </h2>
            <ul className="dashed secondary-text">
              <li>Designed for civilian & military application aircrafts</li>
              <li>Built-In obstacle and collision prevention systems</li>
              <li>World's first ever AI powered managed Airspace for UAVs</li>
            </ul>
            <button className="moreInfo">
              <a href="#">
                More info
                <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </button>
            <div className="spec_image">
              <img src={midsection1} alt="Specifications" />
              <p className="caption">
                First ever built compact-software based UAV airspace control, compatible on any device with active network.{" "}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="spec_image">
              <img src={midsection3} alt="Specifications" />
              <p className="caption">
                Dronetech X offers a barrage of features including path planning and highest-class collision prevention, developed and published proby Dronetech.{" "}
              </p>
            </div>
            <div className="spec_image">
              <img src={midsection2} alt="Specifications" />
              <p className="caption">
              The world's most advanced and powerful AI-powered UAV navigation <br /> and obstacle protection system.{" "}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
