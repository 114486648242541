import React from "react";
import "./Overview.css";
import Container from "../Container/Container";
import RedLine from "../RedLine/RedLine";
import overview from "../../assets/pexels-yaroslav-shuraev-1834403 (1).jpeg";
import FindMore from "../FindMore/FindMore";

export default function Overview() {
  return (
    <section className="overview">
      <Container type="smallest">
        <div className="content">
          <img src={overview} alt="No image found" />
          <div className="right">
            <RedLine type="small" />
            <h3 className="title">
              Introducing <br />
              Dronetech X
              <br /> state-of-the-art UAV Airspace
            </h3>
            <p className="secondary-text">
              Powered by the <br /> world of artificial intelligence
            </p>
            <FindMore text="Find out more" />
          </div>
        </div>
      </Container>
    </section>
  );
}
