import React from "react";
import "./intro.css";
import RedLine from "../RedLine/RedLine";

export default function Intro() {
  return (
    <section className="intro">
      <div className="bgImage">
        <div className="content">
          <RedLine />
         
        </div>
      </div>
    </section>
  );
}
